import WOW from "../../../node_modules/wow.js/dist/wow"

/**
 *  Themes: Velonic Admin theme
 *
 **/

!(function ($) {
    "use strict"

    /**
    Sidebar Module
    */
    var SideBar = function () {
        ;(this.$body = $("body")),
            (this.$sideBar = $("aside.left-panel")),
            (this.$navbarToggle = $(".navbar-toggle")),
            (this.$navbarItem = $(
                "aside.left-panel nav.icon_nav > ul > li > div > a"
            ))
    }

    //initilizing
    ;(SideBar.prototype.init = function () {
        //on toggle side menu bar
        var $this = this
        $(document).on("click", ".navbar-toggle", function () {
            $this.$sideBar.toggleClass("collapsed")
        })

        $(window).on("resize", function () {
            if (
                $(window).width() > 768 &&
                $this.$sideBar.hasClass("collapsed")
            ) {
                $this.$sideBar.removeClass("collapsed")
            }
        })

        this.$navbarItem.click(function () {
            let dropped_down = false
            let bttn_clicked = $(this).attr("id").split("_")[0]
            if ($("#" + bttn_clicked + "_drop_down").hasClass("active")) {
                dropped_down = true
            }
            $(".icon_drop_ul").slideUp(300)
            $(".icon_drop_li").removeClass("active")
            $(".icon_drop_ul").removeClass("active")

            if (!dropped_down) {
                $("#" + bttn_clicked + "_drop_down").slideDown(300)
                $("#" + bttn_clicked + "_drop_li").addClass("active")
                $("#" + bttn_clicked + "_drop_down").addClass("active")
                if (
                    bttn_clicked === "sp" ||
                    bttn_clicked === "accounts" ||
                    bttn_clicked === "so"
                ) {
                    $("aside").animate(
                        {
                            scrollTop: $("aside").get(0).scrollHeight,
                        },
                        300
                    )
                }
            }
        })

        //adding nicescroll to sidebar
        if ($.isFunction($.fn.niceScroll)) {
            $("aside.left-panel:not(.collapsed)").niceScroll({
                cursorcolor: "#D7171F",
                cursorborder: "0px solid #fff",
                cursoropacitymax: "0.5",
                cursorborderradius: "0px",
                autohidemode: "leave",
                cursorwidth: "10px",
            })
            let reScroll = true
            $("aside.left-panel")
                .on("mousedown", function () {
                    reScroll = true
                })
                .on("mouseover wheel", function () {
                    if (reScroll) {
                        $(this).getNiceScroll().resize()
                        reScroll = false
                    }
                })
        }
    }),
        //exposing the sidebar module
        ($.SideBar = new SideBar()),
        ($.SideBar.Constructor = SideBar)
})(window.jQuery),
    //portlets
    (function ($) {
        "use strict"

        /**
    Portlet Widget
    */
        var Portlet = function () {
            ;(this.$body = $("body")),
                (this.$portletIdentifier = ".portlet"),
                (this.$portletCloser = '.portlet a[data-toggle="remove"]'),
                (this.$portletRefresher = '.portlet a[data-toggle="reload"]')
        }

        //on init
        ;(Portlet.prototype.init = function () {
            // Panel closest
            var $this = this
            $(document).on("click", this.$portletCloser, function (ev) {
                ev.preventDefault()
                var $portlet = $(this).closest($this.$portletIdentifier)
                var $portlet_parent = $portlet.parent()
                $portlet.remove()
                if ($portlet_parent.children().length == 0) {
                    $portlet_parent.remove()
                }
            })

            // Panel Reload
            $(document).on("click", this.$portletRefresher, function (ev) {
                ev.preventDefault()
                var $portlet = $(this).closest($this.$portletIdentifier)
                // This is just a simulation, nothing is going to be reloaded
                $portlet.append(
                    '<div class="panel-disabled"><div class="loader-1"></div></div>'
                )
                var $pd = $portlet.find(".panel-disabled")
                setTimeout(function () {
                    $pd.fadeOut("fast", function () {
                        $pd.remove()
                    })
                }, 500 + 300 * (Math.random() * 5))
            })
        }),
            //
            ($.Portlet = new Portlet()),
            ($.Portlet.Constructor = Portlet)
    })(window.jQuery),
    //main app module
    (function ($) {
        "use strict"

        var VelonicApp = function () {
            ;(this.VERSION = "1.0.0"),
                (this.AUTHOR = "Coderthemes"),
                (this.SUPPORT = "coderthemes@gmail.com"),
                (this.pageScrollElement = "html, body"),
                (this.$body = $("body"))
        }

        //initializing tooltip
        ;(VelonicApp.prototype.initTooltipPlugin = function () {
            $.fn.tooltip && $('[data-toggle="tooltip"]').tooltip()
        }),
            //initializing popover
            (VelonicApp.prototype.initPopoverPlugin = function () {
                $.fn.popover && $('[data-toggle="popover"]').popover()
            }),
            //initializing nicescroll
            (VelonicApp.prototype.initNiceScrollPlugin = function () {
                //You can change the color of scroll bar here
                $.fn.niceScroll &&
                    $(".nicescroll").niceScroll({
                        cursorcolor: "#9d9ea5",
                        cursorborderradius: "0px",
                    })
            }),
            //initializing knob
            (VelonicApp.prototype.initKnob = function () {
                if ($(".knob").length > 0) {
                    $(".knob").knob()
                }
            }),
            //initilizing
            (VelonicApp.prototype.init = function () {
                this.initTooltipPlugin(),
                    this.initPopoverPlugin(),
                    this.initNiceScrollPlugin(),
                    this.initKnob(),
                    //creating side bar
                    $.SideBar.init(),
                    //creating portles
                    $.Portlet.init()
            }),
            ($.VelonicApp = new VelonicApp()),
            ($.VelonicApp.Constructor = VelonicApp)
    })(window.jQuery),
    //initializing main application module
    (function ($) {
        "use strict"
        $.VelonicApp.init()
    })(window.jQuery)

/* ==============================================
7.WOW plugin triggers animate.css on scroll
=============================================== */
var wow = new WOW({
    boxClass: "wow", // animated element css class (default is wow)
    animateClass: "animated", // animation css class (default is animated)
    offset: 50, // distance to the element when triggering the animation (default is 0)
    mobile: false, // trigger animations on mobile devices (true is default)
})
wow.init()
