// Sentry SDK configuration
// Initialise Sentry first in order to catch any errors including those from loading the rest of the JS
import * as sentry from "./sentry"

// Import all JavaScript libraries used anywhere in the codebase, so that Webpack will
// create a bundle containing all these dependencies

import "jquery"
import "bootstrap"
import "bootstrap-datepicker"
import "chart.js"
import "chartjs-plugin-annotation"
import "hyperscript.org"
import "jquery-ui"
import "jquery.nicescroll"
import "jquery.scrollto"
import "jquery-ui/ui/widgets/sortable"
import "jsqr"
import "moment"
import "moment-timezone"
import "popper.js"
import "wow.js"
import "./jquery.app"
import "vis"
import "datatables.net-autofill-bs"
import "datatables.net-bs"
import "datatables.net-fixedcolumns-bs"
import "datatables.net-fixedheader-bs"
import "datatables.net-keytable-bs"
import "datatables.net-responsive-bs"
import "datatables.net-rowgroup-bs"
import "datatables.net-scroller-bs"
import "datatables.net-select-bs"
import "datatables.net-plugins/sorting/natural"
import "select2"
import "cookieconsent"
import "django-select2"
import "datetime-moment"
import "daterangepicker/daterangepicker"
import "./bootstrap-switch.min"
import "./natural_datatable_sorting"
import "./iframer"
import "./thehub"
import "htmx.org"
import "./pace"
import "./teleport"

// this declares `swal` as a global variable
import "sweetalert"

sentry.setDatatableErrMode()

$(document).on("ajaxComplete", function (event, xhr, settings) {
    if (xhr.status === 302) {
        event.preventDefault()
        location.reload()
    }
})
