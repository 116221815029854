// based on natural-time-delta plug in: //cdn.datatables.net/plug-ins/1.10.21/sorting/natural-time-delta.js
// added date_eu plug in: //cdn.datatables.net/plug-ins/1.10.21/sorting/date-eu.js
jQuery.extend(jQuery.fn.dataTableExt.oSort, {
    "natural-time-delta-pre": function (data) {
        // For Reporting People Insert where Length of Service is captured in format: "1y 2m 4d"
        var result = 0
        var pattern = /(\d+\s*y,?\s*)?(\d+\s*m,?\s*)?(\d+\s*d?\s*)?/i
        var format_time_element = function (el, splitter, mul) {
            if (el === undefined) {
                return 0
            }
            return parseFloat(el.split(splitter)[0].trim()) * mul
        }
        var matches = data.match(pattern)
        matches.reverse()
        var time_elements = [
            { splitter: "d", name: "day", mul: 1 * 60 * 60 * 24 },
            { splitter: "w", name: "month", mul: 1 * 60 * 60 * 24 * 7 * 30 },
            {
                splitter: "w",
                name: "year",
                mul: 1 * 60 * 60 * 24 * 7 * 30 * 12,
            },
        ]

        time_elements.forEach(function (el, i) {
            var val = format_time_element(matches[i], el["splitter"], el["mul"])
            result += val
        })
        return result || -1
    },

    "natural-time-delta-asc": function (a, b) {
        return a < b ? -1 : a > b ? 1 : 0
    },

    "natural-time-delta-desc": function (a, b) {
        return a < b ? 1 : a > b ? -1 : 0
    },
    "date-eu-pre": function (date) {
        date = date.replace(" ", "")

        if (!date) {
            return 0
        }

        var year
        var eu_date = date.split(/[\.\-\/]/)

        /*year (optional)*/
        if (eu_date[2]) {
            year = eu_date[2]
        } else {
            year = 0
        }

        /*month*/
        var month = eu_date[1]
        if (month.length == 1) {
            month = 0 + month
        }

        /*day*/
        var day = eu_date[0]
        if (day.length == 1) {
            day = 0 + day
        }

        return (year + month + day) * 1
    },

    "date-eu-asc": function (a, b) {
        return a < b ? -1 : a > b ? 1 : 0
    },

    "date-eu-desc": function (a, b) {
        return a < b ? 1 : a > b ? -1 : 0
    },
})
