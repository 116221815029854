$(document).ready(function () {
    window.cookieconsent.initialise({
        position: "bottom-right",
    })

    initDatePicker()
    initRemainingCharacters()

    $(".bootstrapswitch").bootstrapSwitch({
        size: "mini",
        handleWidth: 30,
        onText: "Y",
        offText: "N",
    })

    $("#dark_mode_switch").on("click", function () {
        $.ajax({
            method: "GET",
            url: "/dark_mode/",
        })
            .done(function (result) {
                if (result == "True") {
                    $("#dark_mode_switch span").text("Light")
                    $("body").addClass("dark")
                    if ($(".left-panel").hasClass("alten-aside")) {
                        $(".left-panel").addClass("dark")
                        $(".alten-logo").attr(
                            "src",
                            "static/images/alten-dark.png"
                        )
                    }
                } else {
                    $("#dark_mode_switch span").text("Dark")
                    $("body").removeClass("dark")
                    if (
                        $(".left-panel").hasClass("alten-aside") &&
                        $(".left-panel").hasClass("dark")
                    ) {
                        $(".left-panel").removeClass("dark")
                        $(".alten-logo").attr(
                            "src",
                            "static/images/alten-rgb.png"
                        )
                    }
                }
            })
            .fail(function (jqxhr, textStatus, error) {
                window.alert("Unable to change theme!")
            })
    })

    $("table").on("click", ".clickable-row-nt", function () {
        window.open($(this).data("url"), "_blank")
    })
    $("table").on("click", ".clickable-row", function () {
        window.location.href = $(this).data("url")
    })
    $("table").on("click", ".clickable-row-iframe", function () {
        window.top.location.href = $(this).data("url")
    })

    document.body.addEventListener("async_download_triggered", function (evt) {
        swal(
            "Info",
            "Your export is now being generated and will be available shortly in the download center.",
            "info"
        )
        updateDownloads()
    })

    htmx.on("htmx:afterSettle", function () {
        // Initialise Select2Widgets added to the DOM by htmx
        $(".django-select2").each(function () {
            const $parentModal = $(this).closest(".modal")
            const isInModal = $parentModal.length > 0

            if (isInModal) {
                // The dropdownParent option is required to get the dropdown search to work in modals
                $(this).select2({
                    dropdownParent: $parentModal,
                })
            } else {
                $(this).select2()
            }
        })
    })
})

$.fn.dataTable.moment("D MMM YYYY")
$.fn.dataTable.moment("D MMM YYYY, HH:mm:ss")

function initDatePicker($selector) {
    const $target = $selector || $(".datepicker")
    $target
        .datepicker({
            format: "dd/mm/yyyy",
            autoclose: true,
            calendarWeeks: true,
            clearBtn: true,
            todayHighlight: true,
            weekStart: "1",
        })
        .attr("autocomplete", "off")
}

$(".hrmakesure").click(function (e) {
    e.preventDefault()
    try {
        var linkURL = $(this).attr("href")
    } catch (err) {
        var linkURL = django.jQuery(this).attr("href")
    }
    HRwarnBeforeRedirect(linkURL)
})

function HRwarnBeforeRedirect(linkURL) {
    swal({
        title: "Continue?",
        text: "The page you are accessing may contain sensitive personal data.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
    }).then((willProceed) => {
        if (willProceed) {
            window.location.href = linkURL
        }
    })
}

function currency_formatter(value, currency_symbol) {
    if (value !== undefined) {
        value = parseFloat(value)
        if (value >= 0) {
            return (
                currency_symbol +
                value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")
            )
        } else {
            return (
                "-" +
                currency_symbol +
                (value * -1).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")
            )
        }
    } else {
        return ""
    }
}

const updateRemainingCharacters = function ($field) {
    const $remainingCharacters = $field
        .parent()
        .next("[id^='remaining-characters']")
        .first()
    const max_length = parseInt($field.attr("maxlength"))
    const current_length = $field.val().length
    const characters_remaining = max_length - current_length
    if (characters_remaining === 0) {
        // Make the text red if the characters are over the maximum
        $remainingCharacters.addClass("text-danger")
    } else {
        $remainingCharacters.removeClass("text-danger")
    }
    $remainingCharacters.text(`${characters_remaining} characters remaining`)
}

const initRemainingCharacters = function () {
    $(".remaining-characters-relevant").each(function () {
        updateRemainingCharacters($(this))
    })

    $(".remaining-characters-relevant").on("change keyup paste", function () {
        updateRemainingCharacters($(this))
    })
}

// Cookie and CSRF

function getCookie(name) {
    var cookieValue = null
    if (document.cookie && document.cookie !== "") {
        var cookies = document.cookie.split(";")
        for (var i = 0; i < cookies.length; i++) {
            var cookie = jQuery.trim(cookies[i])
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === name + "=") {
                cookieValue = decodeURIComponent(
                    cookie.substring(name.length + 1)
                )
                break
            }
        }
    }
    return cookieValue
}

let csrftoken = getCookie("csrftoken")

function csrfSafeMethod(method) {
    // these HTTP methods do not require CSRF protection
    return /^(GET|HEAD|OPTIONS|TRACE)$/.test(method)
}

$.ajaxSetup({
    beforeSend: function (xhr, settings) {
        if (!csrfSafeMethod(settings.type) && !this.crossDomain) {
            xhr.setRequestHeader("X-CSRFToken", csrftoken)
        }
    },
})

// ZoomBop

$(window).scroll(function () {
    if ($(this).scrollTop()) {
        $("#zoomBop").fadeIn()
    } else {
        $("#zoomBop").fadeOut()
    }
})

$("#zoomBop").click(function () {
    $("html, body").animate({ scrollTop: 0 }, 1000)
})

export {
    getCookie,
    currency_formatter,
    initDatePicker,
    initRemainingCharacters,
}
